.preview {
  padding: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    color: var(--indigo-dark);
    margin-bottom: 8px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-bottom: 8px;
  }
  &__content {
    height: 100%;
    flex-grow: 1;
    overflow: scroll;
  }
  &__buttons {
    display: flex;
    justify-content: center;
  }
  &__button {
    margin-right: 8px!important;
    &:last-child {
      margin-right: 0!important;
    }
  }
  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    padding: 8px;
    box-shadow: 0 2px 4px 1px rgba(33, 39, 54, 0.16), 0 0 1px 0 rgba(33, 39, 54, 0.04);
  }

  &__file {
    font-size: 0.875rem;
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.04);  
    }
    label {
      cursor: pointer;
    }
    input {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}