.main {
  display: flex;
  height: calc(100vh - 60px);

  &__content {
    padding: 8px 12px;
    width: 0;
    flex-grow: 1;
    background-color: #f5f5f5;
  }

  &__aside {
    width: 560px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
  }
}