.block {
  border-bottom: 1px solid var(--grey);
  padding: 8px 4px;
  font-size: 14px;
  display: flex;
  &__type {
    width: 150px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    display: flex;
  }
  &__info {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-right: 2px;
    display: flex;
    flex-direction: column;
    & > span{
      margin-bottom: 2px;
    }
  }
  &__subtitle {
    text-transform: uppercase;
    font-style: italic;
    color: #64b5f6;
  }
}