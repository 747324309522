.main {
  display: flex;
  justify-content: center;
  padding-top: 48px;

  &__form {
    padding: 40px 32px;
  
    &-button {
      margin-top: 48px;
      display: flex;
      justify-content: center;
    }
  }

  &__input {
    display: flex;
    align-items: center;
  }

  &__icon-wrapper {
    margin-right: 8px;
  }
}