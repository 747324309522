.page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__main {
    padding: 8px 12px;
    flex-grow: 1;
    background-color: #f5f5f5;
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  &__label {
    &:global(.MuiFormLabel-root) {
      font-size: 12px;
    }
  }
  &__main {
    flex-grow: 1;
    height: 0;
  }
  &__framerate {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 16px;
    
    :global(.MuiInputBase-root) {
      margin: 0;
      width: 100%;
    }
  }
  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }
  &__header {
    display: flex;
    margin-bottom: 16px;
    height: 100%;
    &-input {
      width: calc(100% - 16px);
      position: relative;
    }
    &-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: grey;
      padding-top: 8px;
      padding-top: 18px;
      padding-left: 12px;
    }
  }
  &__scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}

.input-file {
  border-bottom: 1px solid grey;
  height: 48px;
  margin-left: 24px;
  width: 100%;
  max-width: 200px;
  margin-right: 16px;
  display: flex;
  align-items: flex-end;
  &__text {
    display: flex;
    padding-bottom: 9px;
    color: rgb(123, 123, 123);
    cursor: pointer;
    width: 100%;
    &--selected {
      color: rgb(0, 0, 0);
      font-size: 14px;
    }
  }
  &__input {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

.wrap {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.mr-4 {
  margin-right: 4px!important;
}