.header {
  color: white;
  margin-bottom: 16px;
  height: 60px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  position: relative;
  margin-bottom: 0;
  &__button {
    &:global(.MuiButton-root){
      color: white;
    }
  }

  :global(.MuiGrid-container) {
    align-items: center;
  }
}
