@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.toolbar {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -43px;
    .rdw-editor-toolbar {
      border: 1px solid rgba(0, 0, 0, 0.35);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin-bottom: 0;
      border-bottom: initial;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }
    .rdw-option-wrapper {
      width: 30px;
      height: 30px;
      border: 1px solid rgba(0, 0, 0, 0.35);
      &.rdw-option-active {
        background-color: #cad1fd;
        &.rdw-colorpicker-option-active {
          background-color: #cad1fd;
          box-shadow: 0px 0px 1px 1px #BFBDBD;
        }
      }
    }
    .rdw-dropdown-wrapper {
      width: 30px;
      height: 30px;
      border: 1px solid rgba(0, 0, 0, 0.35);
    }
    .rdw-editor-main {
      border: 1px solid rgba(0, 0, 0, 0);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0 8px;
      transition: border 0.15s ease-in-out;
    }
    .rdw-colorpicker-modal {
      padding: 8px;
      border-radius: 8px;
      height: 125px;
    }
    .rdw-colorpicker-option {
      box-shadow: none;
    }
  }
}

.toolbar {
  &__wrapper {
    &--show {
      box-shadow: 0 0 1px 0 rgba(33, 39, 54, 0.04);
      .rdw-editor-toolbar {
        opacity: 1;
      }
      .rdw-editor-main {
        border: 1px solid rgba(0, 0, 0, 0.35);
      }
    }
    &--empty {
      .rdw-editor-main {
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color: rgba(118, 143, 191, 0.05);
      }
    }
  }
}

.draft-js {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  &__spinner {
    position: absolute;
    background-color: rgba(118, 143, 191, 0.25);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__placeholder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 17px 0 0 10px;
    color: #797979;
  }
}