.card {
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px 1px rgba(33, 39, 54, 0.16), 0 0 1px 0 rgba(33, 39, 54, 0.04);
  margin-bottom: 8px;
  &__label {
    background-color: #768fbf;
    color: white;
    width: 100px;
    display: block;
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    margin-right: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin-right: 8px!important;
      &:last-child {
        margin-right: 0!important;
      }
  }

  &__checkbox + .MuiTypography-root {
    font-size: 12px!important;
  }

  &__menu {
    padding: 6px!important;
    min-width: 32px!important;
    border-radius: 50%!important;
    background-color: #768fbf!important;
  }
}